import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useIsPreviewMode } from "@/core/context/PreviewModeContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import WarningCircleIcon from "@/core/ui/disco/icons/color-icons/warning-light.svg"
import experienceCloningCover from "@/core/ui/images/covers/default-experience-cloning-cover.png"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ExperienceAdminDropdown from "@/experience/admin/dropdown/ExperienceAdminDropdown"
import ProductCardCallToActionButton from "@/product/card/ProductCardCallToActionButton"
import ProductCardDetails, { ProductTagType } from "@/product/card/ProductCardDetails"
import { ProductCardFragment$key } from "@/product/card/__generated__/ProductCardFragment.graphql"
import defaultUserCoverPhoto from "@assets/images/covers/default-product-card-cover.png"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoLink, DiscoText } from "@disco-ui"
import { LinearProgress, useTheme } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import { isValidColor } from "@utils/color/colorUtils"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import classNames from "classnames"
import { JssStyle } from "jss"
import { useEffect, useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

export type CoverPosition = "top" | "left" | "none"

type ProductCardProps = {
  testid?: string
  linkLocation?: "dashboard" | "curriculum"
  productKey: ProductCardFragment$key
  className?: string
  hideButton?: boolean
  hideAdminActions?: boolean
  buttonBorderRadius?: number
  titleVariant?: TextVariantWithModifiers
  disableLink?: boolean
  addShadow?: boolean
  textColor?: string
  footer?: React.ReactNode
  onClick?: () => void
  isDashboardBlock?: boolean
  tagType?: ProductTagType
} & Partial<Omit<StyleProps, "isLive">>

export default function ProductCard(props: ProductCardProps) {
  const {
    testid = "ProductCard",
    productKey,
    className,
    hideButton,
    coverPosition = "top",
    hideAdminActions = false,
    buttonBorderRadius,
    disableLink = false,
    addShadow = false,
    linkLocation = "dashboard",
    footer,
    onClick,
    isDashboardBlock = false,
    tagType = "chip",
    titleVariant,
  } = props

  const history = useHistory()
  const isPreviewMode = useIsPreviewMode()
  const drawer = useGlobalDrawer("registration")
  const activeOrganization = useActiveOrganization()!

  const product = useFragment<ProductCardFragment$key>(
    graphql`
      fragment ProductCardFragment on Product {
        id
        slug
        cover
        sourceCloneJob {
          status
        }
        badge {
          ...BadgeFragment
        }
        viewerMembership {
          role
        }
        ...ProductCardCallToActionButtonFragment
        ...ExperienceAdminDropdownFragment
        ...ProductCardDetailsFragment
      }
    `,
    productKey
  )

  const isBeingCloned = product.sourceCloneJob?.status === "in_progress"

  // For left-positioned cover, the cover width is calculated from the height of the card
  // content to maintain our aspect ratio.
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [leftCoverWidth, setLeftCoverWidth] = useState<number | undefined>()
  useEffect(() => {
    // Nothing to do when not a left cover photo.
    if (!contentRef.current || coverPosition !== "left") {
      setLeftCoverWidth(undefined)
      return () => undefined
    }

    // Immediately update from current card height.
    setLeftCoverWidth(contentRef.current.clientHeight * ASPECT_RATIOS.COVER_PHOTO)
    // Observe all changes to the card height and update accordingly.
    const observer = new ResizeObserver(() => {
      if (contentRef.current) {
        setLeftCoverWidth(contentRef.current.clientHeight * ASPECT_RATIOS.COVER_PHOTO)
      }
    })
    observer.observe(contentRef.current)
    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current)
      }
    }
  }, [coverPosition])

  // Default style settings that can be overriden from props (used for landing page mostly)
  const theme = useTheme()
  const textColor = isValidColor(props.textColor)
    ? props.textColor
    : theme.palette.text.primary
  const classes = useStyles({
    coverPosition,
    leftCoverWidth,
    borderRadius: props.borderRadius ?? (theme.measure.borderRadius.big as number),
    backgroundColor: props.backgroundColor || theme.palette.background.paper,
    addShadow,
  })

  // Clone in progress shows just a skeleton product card.
  if (isBeingCloned) {
    return (
      <div
        className={classNames(classes.card, className)}
        data-testid={`${testid}.clone-in-progress`}
      >
        <CoverPhoto coverPhoto={experienceCloningCover} customClassName={classes.cover} />
        {/* Loading bar */}
        <LinearProgress
          variant={"indeterminate"}
          className={classes.cloningProgressBar}
        />
        <div ref={(e) => (contentRef.current = e)} className={classes.content}>
          <ProductCardDetails
            productKey={product}
            textColor={textColor}
            testid={testid}
          />
          <div className={classes.footer}>
            <div className={classes.ctaWrapper}>
              <WarningCircleIcon />
              <DiscoText marginLeft={1} variant={"body-sm"} color={"text.secondary"}>
                {"Duplication in progress"}
              </DiscoText>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <DiscoLink
      onClick={() => {
        if (onClick) onClick()
        if (disableLink) return

        if (activeOrganization.viewerIsOwnerOrAdmin || product.viewerMembership) {
          // If we are a community admin or are already enrolled in the product, go straight to the dashboard
          history.push(
            generatePath(
              linkLocation === "curriculum"
                ? ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT
                : ROUTE_NAMES.PRODUCT.DASHBOARD,
              {
                productSlug: product.slug,
              }
            )
          )
        } else {
          // Otherwise, open the drawer to register
          drawer.setParams({
            drawerRegistrationExperienceId: product.id,
          })
        }
      }}
      className={classNames(classes.card, className)}
      data-testid={`${testid}.link`}
    >
      {!isPreviewMode && !hideAdminActions && (
        <ExperienceAdminDropdown
          productKey={product}
          moreActionsButtonClassName={classes.actionsDropdown}
          rotateIcon
        />
      )}
      {product.cover && coverPosition !== "none" && (
        <CoverPhoto
          coverPhoto={product.cover || defaultUserCoverPhoto}
          customClassName={classes.cover}
        />
      )}
      <div ref={(e) => (contentRef.current = e)} className={classes.content}>
        <ProductCardDetails
          productKey={product}
          textColor={textColor}
          testid={testid}
          tagType={tagType}
          titleVariant={titleVariant}
        />

        {footer}
        {/* CTA */}
        {!hideButton && (
          <div className={classes.footer}>
            <div className={classes.ctaWrapper}>
              <ProductCardCallToActionButton
                productKey={product}
                borderRadius={buttonBorderRadius}
                isDashboardBlock={isDashboardBlock}
              />
            </div>
          </div>
        )}
      </div>
    </DiscoLink>
  )
}

interface StyleProps {
  coverPosition: CoverPosition
  leftCoverWidth: number | undefined
  borderRadius: number
  backgroundColor: string
  addShadow: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: (props: StyleProps) => ({
    overflow: "hidden",
    backgroundColor: props.backgroundColor,
    borderRadius: props.borderRadius,
    display: "flex",
    height: "100%",
    position: "relative",
    border: theme.palette.constants.borderDashboardCard,
    "&:hover": {
      textDecoration: "none",
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
    ...styleIf(props.coverPosition === "top", {
      flexDirection: "column",
    }),
    ...styleIf(props.addShadow, {
      boxShadow: theme.palette.groovyDepths.xs,
    }),
  }),
  cover: (props: StyleProps) =>
    ({
      ...styleIf(props.coverPosition === "top", {
        borderTopLeftRadius: props.borderRadius,
        borderTopRightRadius: props.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundSize: "cover",
        paddingTop: "50%",
      }),
      ...styleIf(props.coverPosition === "left", {
        flex: `0 1 ${props.leftCoverWidth ?? 50}px`,
        paddingTop: 0,
        borderTopLeftRadius: props.borderRadius,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: props.borderRadius,
        borderBottomRightRadius: 0,
        backgroundSize: "cover",
      }),
    } as JssStyle),
  content: (props: StyleProps) => ({
    padding: theme.spacing(2),
    // Make all cards in a grid the same height with the
    // teachers footer aligned to the bottom.
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // Let the cover photo maintain its aspect ratio without
    // squishing the content TOO much
    ...styleIf(props.coverPosition === "left", {
      flex: "1 0 300px",
      padding: theme.spacing(2.5, 2.5, 2.5, 6.5),
    }),
  }),
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: theme.spacing(1.5),
  },
  ctaWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  cloningProgressBar: {
    height: "8px",
    backgroundColor: theme.palette.groovy.neutral[100],
    "& > div": {
      backgroundColor: theme.palette.success.main,
    },
  },
  actionsDropdown: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    background: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.groovy.neutral[100],
    },
  },
}))

export function ProductCardSkeleton(props: Pick<ProductCardProps, "coverPosition">) {
  const { coverPosition = "top" } = props
  const theme = useTheme()

  return (
    <Skeleton
      variant={"rect"}
      height={coverPosition === "top" ? 330 : 240}
      width={"100%"}
      style={{
        borderRadius: theme.measure.borderRadius.big,
        ...(coverPosition === "left" && {
          height: "130px",
        }),
      }}
    />
  )
}
