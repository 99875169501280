/**
 * @generated SignedSource<<6ecc6aa52839fc82fb08e32f789ed683>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductBadgeStackFragment$data = {
  readonly childProducts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly badge: {
          readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
        } | null;
        readonly id: string;
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ProductBadgeStackFragment";
};
export type ProductBadgeStackFragment$key = {
  readonly " $data"?: ProductBadgeStackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductBadgeStackFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "childProducts"
        ]
      }
    ]
  },
  "name": "ProductBadgeStackFragment",
  "selections": [
    {
      "alias": "childProducts",
      "args": null,
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "__ProductBadgeStack_childProducts_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Badge",
                  "kind": "LinkedField",
                  "name": "badge",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "BadgeFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "70f5b76b7d2674a56d6f7d8b16625af4";

export default node;
