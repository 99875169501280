/**
 * @generated SignedSource<<c6058cb24c76bb9625f8e2597533e7a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductCardDetailsFragment$data = {
  readonly endsAt: string | null;
  readonly id: string;
  readonly name: string;
  readonly startsAt: string | null;
  readonly status: ProductStatus;
  readonly type: ProductType;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment" | "productUtils_useIsProductLiveFragment">;
  readonly " $fragmentType": "ProductCardDetailsFragment";
};
export type ProductCardDetailsFragment$key = {
  readonly " $data"?: ProductCardDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCardDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "productUtils_useIsProductLiveFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "3b46b3aa26a2575f286c880737a598b4";

export default node;
