/**
 * @generated SignedSource<<8e13733ef423077b43ee4b700aab5b41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutFormOrganizationFormSection_Checkout$data = {
  readonly organizationForm: {
    readonly questions: ReadonlyArray<{
      readonly id: string;
      readonly richEditorBody: string;
    }>;
  } | null;
  readonly " $fragmentType": "CheckoutFormOrganizationFormSection_Checkout";
};
export type CheckoutFormOrganizationFormSection_Checkout$key = {
  readonly " $data"?: CheckoutFormOrganizationFormSection_Checkout$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutFormOrganizationFormSection_Checkout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutFormOrganizationFormSection_Checkout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuestionAnswers",
      "kind": "LinkedField",
      "name": "organizationForm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CheckoutQuestion",
          "kind": "LinkedField",
          "name": "questions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richEditorBody",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Checkout",
  "abstractKey": null
};

(node as any).hash = "ae0d54ef9fa61ad8f8579a5cad090955";

export default node;
