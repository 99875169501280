import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ProductCardDetailsFragment$key } from "@/product/card/__generated__/ProductCardDetailsFragment.graphql"
import ProductStatusChip from "@/product/common/status-chip/ProductStatusChip"
import ProductTypeTag from "@/product/common/type-tag/ProductTypeTag"
import ProductUtils from "@/product/util/productUtils"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import useTruncateStyles from "@assets/style/util/useTruncateStyles"
import PulsatingDot from "@components/pulsating-dot/PulsatingDot"
import { DiscoText } from "@disco-ui"
import { lighten } from "@material-ui/core"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type ProductTagType = "chip" | "label"

interface Props {
  productKey: ProductCardDetailsFragment$key
  textColor: string
  testid: string
  tagType?: ProductTagType
  titleVariant?: TextVariantWithModifiers
}

function ProductCardDetails(props: Props) {
  const {
    productKey,
    textColor,
    testid,
    tagType = "chip",
    titleVariant = "body-md-700",
  } = props
  const product = useFragment<ProductCardDetailsFragment$key>(
    graphql`
      fragment ProductCardDetailsFragment on Product {
        id
        name
        type
        status
        startsAt
        endsAt
        ...ProductTypeTagFragment
        ...productUtils_useIsProductLiveFragment
      }
    `,
    productKey
  )

  const startsAt = product.startsAt && new Date(product.startsAt)
  const isLive = ProductUtils.useIsProductLive(product)

  const truncateClasses = useTruncateStyles({ width: "100%", numberOfLines: 2 })
  const classes = useStyles()

  return (
    <div data-testid={`${testid}.CardName.${product.name}`}>
      <div className={classes.tags}>
        {tagType === "chip" ? (
          <ProductTypeTag testid={`${testid}.type-tag`} productKey={product} />
        ) : (
          <DiscoText variant={"body-sm-600-uppercase"} color={"primary.main"}>
            {product.type}
          </DiscoText>
        )}
        {product.status === "draft" && (
          <ProductStatusChip
            status={product.status}
            testid={`${testid}.product-status`}
          />
        )}
      </div>
      <DiscoText
        testid={`ProductCard.CardName.${product.name}`}
        variant={titleVariant}
        setColor={textColor}
        className={truncateClasses.truncateMultipleLines}
      >
        {product.name}
      </DiscoText>
      <div className={classes.startDateRow}>
        {isLive ? (
          <>
            <PulsatingDot />
            <DiscoText
              variant={"body-xs-500-uppercase"}
              color={"error.main"}
              className={classes.liveNowText}
            >
              {"Live Now"}
            </DiscoText>
          </>
        ) : (
          <DiscoText variant={"body-sm"} setColor={lighten(textColor, 0.3)}>
            {getStartDateText()}
          </DiscoText>
        )}
      </div>
    </div>
  )

  /** The text showing the start date/time and duration with format depending on type of product */
  function getStartDateText(): string {
    if (!startsAt) return ""
    return `${formatDateWithOptions({
      format: DATE_FORMAT.DEFAULT,
    })(startsAt)} · ${ProductUtils.displayDuration(product)}`
  }
}

export default ProductCardDetails

const useStyles = makeUseStyles((theme) => ({
  tags: {
    display: "flex",
    width: "fit-content",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  startDateRow: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    marginTop: theme.spacing(0.5),
  },
  liveNowText: {
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
  },
}))
