import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInputProps, DiscoText } from "@disco-ui"
import DiscoNumberInput from "@disco-ui/input/DiscoNumberInput"
import { InputAdornment } from "@material-ui/core"
import { forwardRef } from "react"

type PricingInputProps = Omit<DiscoInputProps, "padding" | "startAdornment">

const PricingInput = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  PricingInputProps
>((props, ref) => {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()

  return (
    <DiscoNumberInput
      {...props}
      ref={ref}
      inputProps={{
        step: "0.01",
        ...props.inputProps,
      }}
      padding={"0 10px"}
      minHeight={"46px"}
      startAdornment={
        <InputAdornment position={"start"} className={classes.currency}>
          <DiscoText color={"groovy.grey.400"}>
            {activeOrganization ? activeOrganization.currency.toUpperCase() : "USD"}
          </DiscoText>
        </InputAdornment>
      }
    />
  )
})

const useStyles = makeUseStyles((theme) => ({
  currency: {
    height: "48px",
    maxHeight: "48px",
    borderRight: theme.palette.constants.borderSmall,
    paddingRight: theme.spacing(1),
  },
}))

export default PricingInput
