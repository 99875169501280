/**
 * @generated SignedSource<<6c03e72d99da90fe16d20b00f2bfda20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentReactionButton_ContentUsageFragment$data = {
  readonly id: string;
  readonly likes: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "ContentReactionButton_ContentUsageFragment";
};
export type ContentReactionButton_ContentUsageFragment$key = {
  readonly " $data"?: ContentReactionButton_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentReactionButton_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentReactionButton_ContentUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentLikeNodeConnection",
      "kind": "LinkedField",
      "name": "likes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "e7aa876afe632fea7d7e36e74b7a1c12";

export default node;
