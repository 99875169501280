import Badge, { BadgeSize } from "@/admin/experiences/badges/Badge"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductBadgeStackFragment$key } from "@/product/card/__generated__/ProductBadgeStackFragment.graphql"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoText } from "@disco-ui"
import pluralize from "pluralize"
import { graphql, useFragment } from "react-relay"
import { ProductBadgeStackFragment_membership$key } from "./__generated__/ProductBadgeStackFragment_membership.graphql"

type Props = {
  productKey?: ProductBadgeStackFragment$key
  membershipProductKey?: ProductBadgeStackFragment_membership$key
  badgeSize?: BadgeSize
  hideBorder?: boolean
}
export default function ProductBadgeStack({
  productKey,
  membershipProductKey,
  badgeSize = 24,
  hideBorder = false,
}: Props) {
  const experienceLabel = useLabel("experience")

  const membershipProduct = useFragment<ProductBadgeStackFragment_membership$key>(
    graphql`
      fragment ProductBadgeStackFragment_membership on Product
      @argumentDefinitions(
        hideDrafts: { type: "Boolean!" }
        hideNonPublic: { type: "Boolean!" }
      ) {
        memberships: membershipBenefits(
          first: 3
          hideDrafts: $hideDrafts
          hideNonPublic: $hideNonPublic
        ) @connection(key: "ProductCountChip_memberships") {
          totalCount
          edges {
            node {
              id
              product {
                id
                badge {
                  ...BadgeFragment
                }
              }
            }
          }
        }
        ...ProductBadgeStackFragment
      }
    `,
    membershipProductKey || null
  )

  const product = useFragment<ProductBadgeStackFragment$key>(
    graphql`
      fragment ProductBadgeStackFragment on Product {
        childProducts(first: 3) @connection(key: "ProductBadgeStack_childProducts") {
          totalCount
          edges {
            node {
              id
              badge {
                ...BadgeFragment
              }
            }
          }
        }
      }
    `,
    productKey || null
  )

  const products = Relay.connectionToArray(product?.childProducts)
  const membershipProducts = Relay.connectionToArray(membershipProduct?.memberships)
  const visibleItems = membershipProducts.length || products.length
  const productBadges =
    membershipProducts.length > 0 ? membershipProducts.map((p) => p.product) : products

  const classes = useStyles({ visibleItems, badgeSize, hideBorder })

  return (
    <div className={classes.container}>
      {productBadges.length > 0 && (
        <div className={classes.stack}>
          {productBadges.map(
            (p) =>
              p && (
                <Badge
                  key={p.id}
                  badgeKey={p.badge}
                  className={classes.badge}
                  size={badgeSize}
                />
              )
          )}
        </div>
      )}
      <DiscoText variant={"body-xs-600"}>
        {pluralize(
          experienceLabel.singular,
          membershipProduct?.memberships?.totalCount ||
            product?.childProducts.totalCount ||
            0,
          true
        )}
      </DiscoText>
    </div>
  )
}

type StyleProps = {
  visibleItems: number
  badgeSize: BadgeSize
  hideBorder: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ hideBorder }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.xl,
    width: "fit-content",
    ...styleIf(!hideBorder, {
      padding: theme.spacing(1, 1.25),
      border: `1.5px solid ${theme.palette.divider}`,
    }),
  }),
  stack: {
    display: "grid",
    gridTemplateColumns: ({ visibleItems, badgeSize }: StyleProps) =>
      `repeat(${visibleItems + 1}, calc(${badgeSize}px / 1.5))`,
  },
  badge: {
    border: `2px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[600]
        : theme.palette.common.white
    }`,
  },
}))
