/**
 * @generated SignedSource<<4a68aca28abef692776be47cced93c6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnassignedMemberGroupTableQuery$variables = {
  id: string;
};
export type UnassignedMemberGroupTableQuery$data = {
  readonly memberGroup: {
    readonly id?: string;
    readonly memberGroupMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly organizationMembership: {
            readonly email: string | null;
            readonly id: string;
          };
          readonly productMembershipId: string | null;
          readonly user: {
            readonly fullName: string;
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserDropdownItemFragment">;
          };
          readonly userId: string;
          readonly " $fragmentSpreads": FragmentRefs<"AdminMemberGroupTableRowFragment">;
        };
      }>;
    };
    readonly productId?: string | null;
  } | null;
};
export type UnassignedMemberGroupTableQuery = {
  response: UnassignedMemberGroupTableQuery$data;
  variables: UnassignedMemberGroupTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productMembershipId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnassignedMemberGroupTableQuery",
    "selections": [
      {
        "alias": "memberGroup",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "memberGroupMemberships",
                "args": null,
                "concreteType": "MemberGroupMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "unassignedMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberGroupMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MemberGroupMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "UserDropdownItemFragment"
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationMembership",
                            "kind": "LinkedField",
                            "name": "organizationMembership",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AdminMemberGroupTableRowFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnassignedMemberGroupTableQuery",
    "selections": [
      {
        "alias": "memberGroup",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": "memberGroupMemberships",
                "args": null,
                "concreteType": "MemberGroupMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "unassignedMembers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberGroupMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MemberGroupMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationMembership",
                            "kind": "LinkedField",
                            "name": "organizationMembership",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductMembership",
                            "kind": "LinkedField",
                            "name": "productMembership",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9629c870282a9097f8a42c74d515350",
    "id": null,
    "metadata": {},
    "name": "UnassignedMemberGroupTableQuery",
    "operationKind": "query",
    "text": "query UnassignedMemberGroupTableQuery(\n  $id: ID!\n) {\n  memberGroup: node(id: $id) {\n    __typename\n    ... on MemberGroup {\n      id\n      productId\n      memberGroupMemberships: unassignedMembers {\n        edges {\n          node {\n            id\n            userId\n            user {\n              id\n              fullName\n              ...UserDropdownItemFragment\n            }\n            organizationMembership {\n              id\n              email\n            }\n            productMembershipId\n            ...AdminMemberGroupTableRowFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AdminMemberGroupTableRowFragment on MemberGroupMembership {\n  id\n  organizationMembership {\n    role\n    email\n    id\n  }\n  productMembership {\n    role\n    email\n    id\n  }\n  user {\n    id\n    fullName\n    ...ProfileAvatarWithDetailsFragment\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n\nfragment UserDropdownItemFragment on User {\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "d7fb0c64b5a55c19684511aced0e4a98";

export default node;
