import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ConnectStripeBanner from "@/payment/stripe-integration/banner/ConnectStripeBanner"
import PricingInput from "@/pricing/input/PricingInput"
import { decimalize } from "@/pricing/pricingUtils"
import {
  PricingFormFields_AvailablePlansQuery,
  PricingFormFields_AvailablePlansQuery$data,
} from "@/pricing/__generated__/PricingFormFields_AvailablePlansQuery.graphql"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import MembershipPlanExperiencePricingListItem from "@/product/settings/pricing/MembershipPlanExperiencePricingListItem"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { Fragment, useRef } from "react"
import { graphql } from "relay-runtime"

type Plan = NodeFromConnection<
  NonNullable<
    PricingFormFields_AvailablePlansQuery$data["organization"]
  >["membershipPlans"]
>

export type ExperienceSettingsFormStore = FormStore<
  ExperienceSettingsFormState,
  ExperienceSettingsFormMutation
>

interface Props extends TestIDProps {
  form: ExperienceSettingsFormStore | PathwaySettingsFormStore
}

function PricingFormFields({ testid = "PricingFormFields", form }: Props) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const initialBenefits = useRef(form.state.benefits)
  const membersLabel = useLabel("admin_member")

  const { organization } =
    Relay.useSkippableLazyLoadQuery<PricingFormFields_AvailablePlansQuery>(
      graphql`
        query PricingFormFields_AvailablePlansQuery($id: ID!) {
          organization: node(id: $id) {
            ... on Organization {
              id
              membershipPlans: products(type: "membership_plan") {
                edges {
                  node {
                    id
                    name
                    ...MembershipPlanExperiencePricingListItemFragment
                  }
                }
              }
            }
          }
        }
      `,
      { id: activeOrganization?.id || "" },
      { skip: form.state.pricingType !== "per-plan" }
    )

  const availablePlans = Relay.connectionToArray(organization?.membershipPlans)
  const benefitsByPlanId = ArrayUtils.mapBy(form.state.benefits, "membershipPlanId")

  return (
    <>
      <DiscoFormControl
        label={"Pricing"}
        testid={testid}
        variant={"two-column"}
        marginBottom={0.5}
        classes={{ root: classes.container, input: classes.tabs }}
      >
        <DiscoTabs
          testid={`${testid}.tabs`}
          tabVariant={"grey-track"}
          routes={[
            {
              label: "Free",
              onClick: handleSetFree,
              active: form.state.pricingType === "free",
              testid: "free",
            },
            {
              label: "Paid",
              onClick: handleSetOneTime,
              active: form.state.pricingType === "paid",
              testid: "paid",
            },
            {
              label: "Per-Plan",
              onClick: handleSetPerPlan,
              active: form.state.pricingType === "per-plan",
              testid: "per-plan",
            },
          ]}
        />
      </DiscoFormControl>

      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={1.5}>
        {renderSublabel()}
      </DiscoText>

      {renderPricingFields()}
    </>
  )

  function renderSublabel() {
    switch (form.state.pricingType) {
      case "free":
        return ""
      case "paid":
        return `This price will be charged to all ${membersLabel.plural}, regardless of their membership plan. To set different prices for each membership plan, select Per-Plan pricing above`
      case "per-plan":
        return "The prices set will be charged for members who are on that membership plan."
    }
  }

  function renderPricingFields() {
    // Require a Stripe connection for paid pricings
    if (form.state.pricingType !== "free" && !activeOrganization?.stripeAccountId) {
      return (
        <DiscoFormControl>
          <ConnectStripeBanner testid={"PricingFormFields"} />
        </DiscoFormControl>
      )
    }

    switch (form.state.pricingType) {
      case "free":
        return <></>
      case "paid":
        return (
          <PricingInput
            fullWidth
            data-testid={"PricingFormFields.price"}
            value={form.state.benefits[0].pricing.amountCents / 100}
            onChange={handleUpdateExtraCharge}
          />
        )
      case "per-plan":
        return (
          <DiscoSection className={classes.perPlanList} border marginBottom={1.5}>
            {form.state.benefits.map((benefit, index) => {
              const plan = availablePlans.find((p) => p.id === benefit.membershipPlanId)
              if (!plan) return <></>

              return (
                <Fragment key={plan.id}>
                  {index > 0 && <DiscoDivider marginTop={0} marginBottom={0} />}
                  <MembershipPlanExperiencePricingListItem
                    testid={`PricingFormFields.${plan.name}`}
                    form={form}
                    membershipPlanKey={plan}
                    cta={
                      <PricingInput
                        fullWidth
                        data-testid={`PricingFormFields.${plan.name}.price`}
                        value={benefitsByPlanId[plan.id].pricing.amountCents / 100}
                        onChange={(e) => handleUpdateExtraCharge(e, plan)}
                      />
                    }
                  />
                </Fragment>
              )
            })}
          </DiscoSection>
        )
    }
  }

  function handleSetFree() {
    form.state.pricingType = "free"
    for (const benefit of form.state.benefits) {
      benefit.pricing.kind = "free"
    }
  }

  function handleSetOneTime() {
    form.state.pricingType = "paid"
    for (const benefit of form.state.benefits) {
      benefit.pricing.kind = "one_time"
    }
  }

  function handleSetPerPlan() {
    form.state.pricingType = "per-plan"
    for (const benefit of form.state.benefits) {
      const initialBenefit = initialBenefits.current.find(
        (b) => b.membershipPlanId === benefit.membershipPlanId
      )
      benefit.pricing.kind = "one_time"
      benefit.pricing.amountCents = initialBenefit?.pricing.amountCents ?? 0
    }
  }

  function handleUpdateExtraCharge(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    plan?: Plan
  ) {
    const benefitsToUpdate = plan ? [benefitsByPlanId[plan.id]] : form.state.benefits
    for (const benefit of benefitsToUpdate) {
      benefit.pricing.kind = decimalize(e.target.value) > 0 ? "one_time" : "free"
      benefit.pricing.amountCents = decimalize(parseFloat(e.target.value) * 100, 0)
    }
  }
}

const useStyles = makeUseStyles({
  container: {
    alignItems: "center",
  },
  tabs: {
    display: "flex",
    justifyContent: "flex-end",
  },
  perPlanList: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
})

export function PricingFormFieldsSkeleton({
  testid = "PricingFormFieldsSkeleton",
}: Omit<Props, "form">) {
  const classes = useStyles()

  return (
    <>
      <DiscoFormControl
        label={"Pricing"}
        testid={testid}
        variant={"two-column"}
        marginBottom={0.5}
        classes={{ root: classes.container, input: classes.tabs }}
      >
        <DiscoTabs
          testid={`${testid}.tabs`}
          tabVariant={"grey-track"}
          routes={[
            {
              label: "Free",
              active: true,
              testid: "free",
            },
            {
              label: "One-Time",
              active: false,
              testid: "paid",
            },
            {
              label: "Per-Plan",
              active: false,
              testid: "per-plan",
            },
          ]}
        />
      </DiscoFormControl>
      <DiscoTextSkeleton width={"100%"} marginBottom={1.5} />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(PricingFormFields),
  skeleton: PricingFormFieldsSkeleton,
})
