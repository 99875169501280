/**
 * @generated SignedSource<<a2382ab4173dc7dd6f8c23f926ec8a59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useCompleteCheckoutMutation_Mutation$variables = {
  id: string;
};
export type useCompleteCheckoutMutation_Mutation$data = {
  readonly completeCheckout: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly product: {
        readonly hasEnded: boolean;
        readonly viewerApplication: {
          readonly id: string;
        } | null;
        readonly viewerMembership: {
          readonly id: string;
        } | null;
      };
    } | null;
  };
};
export type useCompleteCheckoutMutation_Mutation = {
  response: useCompleteCheckoutMutation_Mutation$data;
  variables: useCompleteCheckoutMutation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasEnded",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembership",
  "kind": "LinkedField",
  "name": "viewerMembership",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductApplication",
  "kind": "LinkedField",
  "name": "viewerApplication",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCompleteCheckoutMutation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteCheckoutResponse",
        "kind": "LinkedField",
        "name": "completeCheckout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Checkout",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCompleteCheckoutMutation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompleteCheckoutResponse",
        "kind": "LinkedField",
        "name": "completeCheckout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Checkout",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "323643a5e3c521a4671282dce58d9fda",
    "id": null,
    "metadata": {},
    "name": "useCompleteCheckoutMutation_Mutation",
    "operationKind": "mutation",
    "text": "mutation useCompleteCheckoutMutation_Mutation(\n  $id: ID!\n) {\n  completeCheckout(id: $id) {\n    node {\n      id\n      product {\n        hasEnded\n        viewerMembership {\n          id\n        }\n        viewerApplication {\n          id\n        }\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06ba08f849cf49d42c1a63a0b668b1fe";

export default node;
