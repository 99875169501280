import { MemberGroupRole } from "@/admin/members/groups/__generated__/MemberGroupAvatarFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import {
  CreateMemberGroupFormMutation,
  CreateMemberGroupInput,
} from "@/product/common/member-group/common/create/form/__generated__/CreateMemberGroupFormMutation.graphql"
import { CreateMemberGroupFormQuery } from "@/product/common/member-group/common/create/form/__generated__/CreateMemberGroupFormQuery.graphql"
import MemberGroupFormFields, {
  MemberGroupFormFieldsSkeleton,
} from "@/product/common/member-group/common/MemberGroupFormFields"
import { MemberGroupKind } from "@/product/common/member-group/common/tag/__generated__/MemberGroupTagFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay"

export type CreateMemberGroupFormState = Omit<
  CreateMemberGroupInput,
  "userIds" | "kind" | "productId"
> & {
  sourceMemberGroupId: GlobalID
  selectedUserIds: GlobalID[]
  kind: MemberGroupKind
  name: string
  color: string
  role: MemberGroupRole | null
  product: { id: string; name: string } | null
  avatarURL?: string | null
}

interface CreateMemberGroupFormProps {
  sourceMemberGroupId: GlobalID
  onCancel: () => void
  onSave: () => void
  selectedMembershipIds: GlobalID[]
}

function CreateMemberGroupForm({
  /** The ID of the member group that defines the subset of membership to add to the new member group */
  sourceMemberGroupId,
  onCancel,
  onSave,
  selectedMembershipIds,
}: CreateMemberGroupFormProps) {
  const activeOrganization = useActiveOrganization()
  const { node } = useLazyLoadQuery<CreateMemberGroupFormQuery>(
    graphql`
      query CreateMemberGroupFormQuery($memberGroupId: ID!, $membershipIds: [ID!]) {
        node(id: $memberGroupId) {
          ... on MemberGroup {
            id
            __typename
            organizationId
            kind
            name
            color
            role
            product {
              id
              name
            }
            memberGroupMemberships(membershipIds: $membershipIds) {
              edges {
                node {
                  id
                  userId
                }
              }
            }
          }
        }
      }
    `,
    {
      memberGroupId: sourceMemberGroupId,
      membershipIds: selectedMembershipIds,
    },
    { fetchPolicy: "network-only" }
  )
  const sourceMemberGroup = Relay.narrowNodeType(node, "MemberGroup")
  const memberGroupMemberships = Relay.connectionToArray(
    sourceMemberGroup?.memberGroupMemberships
  )

  const form = useFormStore<CreateMemberGroupFormMutation, CreateMemberGroupFormState>(
    graphql`
      mutation CreateMemberGroupFormMutation(
        $input: CreateMemberGroupInput!
        $connections: [ID!]!
      ) {
        response: createMemberGroup(input: $input) {
          node
            @prependNode(connections: $connections, edgeTypeName: "MemberGroupNodeEdge") {
            id
            ...AdminGroupsListRowFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: sourceMemberGroup?.organizationId || "",
      sourceMemberGroupId,
      name: "",
      color: "#FFF5D6",
      selectedUserIds: memberGroupMemberships.map((mgm) => mgm.userId),
      visibility: "everyone",
      description: "",
      kind: "custom",
      role: sourceMemberGroup?.role || null,
      product: sourceMemberGroup?.product || null,
      // Must update assetId and url if the avatar has changed
      assetId: null,
      avatarURL: null,
    }
  )

  if (!sourceMemberGroup) return null
  return (
    <Form
      testid={"CreateMemberGroupForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onCancel}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            testid={"CreateMemberGroupForm.submit"}
            form={form}
            disabled={!form.isChanged || form.isSubmitting}
          >
            {"Create Group"}
          </Form.SubmitButton>
        </>
      }
    >
      <MemberGroupFormFields
        form={form}
        isSubGroup={Boolean(sourceMemberGroup.product?.id)}
      />
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(
      {
        organizationId: sourceMemberGroup!.organizationId,
        productId: form.state.product?.id,
        userIds: form.state.selectedUserIds,
        color: form.state.color,
        name: form.state.name,
        kind: "custom",
        visibility: form.state.product ? null : form.state.visibility,
        description: form.state.description,
        assetId: form.state.assetId,
      },
      {
        connections: [
          ConnectionHandler.getConnectionID(
            activeOrganization!.id,
            "AdminGroupsList__memberGroups"
          ),
        ],
      }
    )

    if (!didSave) return

    displaySuccessToast({
      message: "Group created!",
      testid: "CreateMemberGroupForm.success-toast",
    })
    onSave()
  }
}

export default Relay.withSkeleton<CreateMemberGroupFormProps>({
  component: observer(CreateMemberGroupForm),
  skeleton: MemberGroupFormFieldsSkeleton,
})
