/**
 * @generated SignedSource<<d780199911a93a337a0915a00fa2bd0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PricingFrequency = "annually" | "monthly" | "weekly" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PaymentFormDiscountSection_Pricing$data = {
  readonly frequency: PricingFrequency | null;
  readonly " $fragmentSpreads": FragmentRefs<"pricingUtils_usePricingDisplayValue">;
  readonly " $fragmentType": "PaymentFormDiscountSection_Pricing";
};
export type PaymentFormDiscountSection_Pricing$key = {
  readonly " $data"?: PaymentFormDiscountSection_Pricing$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentFormDiscountSection_Pricing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentFormDiscountSection_Pricing",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "frequency",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "pricingUtils_usePricingDisplayValue"
    }
  ],
  "type": "Pricing",
  "abstractKey": null
};

(node as any).hash = "f54afbdc180d0084670433bc0dd8e3a0";

export default node;
