/**
 * @generated SignedSource<<e8f87b9a4d3e538dbda3fceb09c5db7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentCommentButton_ContentUsageFragment$data = {
  readonly hasViewerCommented: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CommentUtils_useTotalCommentsOnUsageCount">;
  readonly " $fragmentType": "ContentCommentButton_ContentUsageFragment";
};
export type ContentCommentButton_ContentUsageFragment$key = {
  readonly " $data"?: ContentCommentButton_ContentUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentCommentButton_ContentUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentCommentButton_ContentUsageFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentUtils_useTotalCommentsOnUsageCount"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerCommented",
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "f8204f3d37a25eef20fa101d4c2fc3c9";

export default node;
