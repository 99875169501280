/**
 * @generated SignedSource<<63ac2a61a0aa5959e676fd827a3b6be2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MemberGroupKind = "custom" | "default" | "role" | "test_user" | "%future added value";
export type MemberGroupRole = "admin" | "instructor" | "manager" | "owner" | "%future added value";
export type CreateMemberGroupFormQuery$variables = {
  memberGroupId: string;
  membershipIds?: ReadonlyArray<string> | null;
};
export type CreateMemberGroupFormQuery$data = {
  readonly node: {
    readonly __typename: "MemberGroup";
    readonly color: string;
    readonly id: string;
    readonly kind: MemberGroupKind;
    readonly memberGroupMemberships: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly userId: string;
        };
      }>;
    };
    readonly name: string;
    readonly organizationId: string;
    readonly product: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly role: MemberGroupRole | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CreateMemberGroupFormQuery = {
  response: CreateMemberGroupFormQuery$data;
  variables: CreateMemberGroupFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberGroupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "memberGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "membershipIds",
      "variableName": "membershipIds"
    }
  ],
  "concreteType": "MemberGroupMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "memberGroupMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroupMembershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembership",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateMemberGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateMemberGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4eadb7ec4bf599bb8066460fdd3fa94",
    "id": null,
    "metadata": {},
    "name": "CreateMemberGroupFormQuery",
    "operationKind": "query",
    "text": "query CreateMemberGroupFormQuery(\n  $memberGroupId: ID!\n  $membershipIds: [ID!]\n) {\n  node(id: $memberGroupId) {\n    __typename\n    ... on MemberGroup {\n      id\n      __typename\n      organizationId\n      kind\n      name\n      color\n      role\n      product {\n        id\n        name\n      }\n      memberGroupMemberships(membershipIds: $membershipIds) {\n        edges {\n          node {\n            id\n            userId\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c68f10b317334fcb8b2e6484cf4e31e";

export default node;
