import { updateRelayContentReaction } from "@/content/util/contentUtils"
import { useFormStore } from "@/core/form/store/FormStore"
import ReactionFaceIcon from "@/core/ui/iconsax/linear/custom-face-content.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { ContentReactionButtonMutation } from "@/product/common/content/button/__generated__/ContentReactionButtonMutation.graphql"
import { ContentReactionButton_ContentFragment$key } from "@/product/common/content/button/__generated__/ContentReactionButton_ContentFragment.graphql"
import { ContentReactionButton_ContentUsageFragment$key } from "@/product/common/content/button/__generated__/ContentReactionButton_ContentUsageFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { RequireOnlyOne } from "@/types/util/RequireOnlyOne"
import { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"
import {
  DiscoButton,
  DiscoButtonSize,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoEmojiPicker from "@disco-ui/emoji-picker/DiscoEmojiPicker"
import { IconButton, Popover, useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import pluralize from "pluralize"
import React, { useRef, useState } from "react"
import { graphql, useFragment } from "react-relay"

type ContentReactionButtonProps = TestIDProps &
  RequireOnlyOne<
    {
      contentKey: ContentReactionButton_ContentFragment$key
      contentUsageKey: ContentReactionButton_ContentUsageFragment$key
    },
    "contentKey" | "contentUsageKey"
  > & {
    testid: string
    contentId: GlobalID
    contentUsageId?: GlobalID
    hideText?: boolean
    hideNumber?: boolean
    readOnlyReactionButton?: boolean
    size?: DiscoButtonSize
  }
function ContentReactionButton(props: ContentReactionButtonProps) {
  const {
    testid,
    contentKey,
    contentUsageKey,
    contentId,
    contentUsageId,
    hideText,
    hideNumber,
    readOnlyReactionButton,
    size = "medium",
  } = props

  const [showReactionDropdown, setShowReactionDropdown] = useState(false)

  const classes = useStyles()

  const content = useFragment<ContentReactionButton_ContentFragment$key>(
    graphql`
      fragment ContentReactionButton_ContentFragment on Content {
        id
        likes {
          totalCount
        }
      }
    `,
    contentKey || null
  )

  const contentUsage = useFragment<ContentReactionButton_ContentUsageFragment$key>(
    graphql`
      fragment ContentReactionButton_ContentUsageFragment on ContentUsage {
        id
        likes {
          totalCount
        }
      }
    `,
    contentUsageKey || null
  )

  const form = useFormStore<ContentReactionButtonMutation>(
    graphql`
      mutation ContentReactionButtonMutation($input: UpdateContentLikeInput!) {
        response: updateContentLike(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentId,
      contentUsageId,
    }
  )
  const totalReactions =
    content?.likes?.totalCount || contentUsage?.likes?.totalCount || 0
  const anchorRef = useRef<HTMLButtonElement>(null)
  const theme = useTheme()

  return (
    <>
      <div className={classes.buttonContainer}>
        <DiscoTooltip content={readOnlyReactionButton ? null : `Add reaction`}>
          <DiscoButton
            ref={anchorRef}
            testid={`ContentReactionButton.${testid}`}
            leftIcon={<ReactionFaceIcon />}
            color={"grey"}
            borderRadius={size === "xsmall" ? 20 : undefined}
            size={size}
            onClick={handleClick}
            className={classes.reactionButton}
            classes={{
              leftIcon: classes.reactionIcon,
            }}
            // Prevent resizing when the number of reactions changes
            width={size === "xsmall" ? "42px" : hideText ? undefined : "132px"}
            customButtonColor={{
              color:
                theme.palette.type === "dark"
                  ? theme.palette.groovy.onDark[200]
                  : theme.palette.groovy.neutral[700],
              backgroundColor:
                size === "xsmall"
                  ? theme.palette.groovy.neutral[100]
                  : theme.palette.background.paper,
              hover: {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.groovy.neutral[200],
              },
            }}
          >
            {hideNumber ? null : (
              <DiscoText variant={"body-sm-600"} marginLeft={0.5}>
                {hideText
                  ? `${totalReactions}`
                  : pluralize("Reactions", totalReactions, true)}
              </DiscoText>
            )}
          </DiscoButton>
        </DiscoTooltip>
      </div>

      <Popover
        open={showReactionDropdown}
        onClose={handleCloseReactionPicker}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DiscoContainerButton onClick={handleClickReactionPicker}>
          <DiscoEmojiPicker
            onChange={handleSelectEmoji}
            onClose={() => handleCloseReactionPicker}
          />
        </DiscoContainerButton>
      </Popover>
    </>
  )

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (readOnlyReactionButton) return
    event.stopPropagation()
    setShowReactionDropdown(true)
  }

  function handleClickReactionPicker(event: React.MouseEvent) {
    event.stopPropagation() // prevent post drawer from opening search
  }

  function handleCloseReactionPicker(event: React.MouseEvent) {
    event.stopPropagation()
    setShowReactionDropdown(false)
  }

  async function handleSelectEmoji(emoji: EmojiObject) {
    setShowReactionDropdown(false)
    if (emoji) {
      const emojiCode = emoji.native
      const emojiAlias = emoji.colons

      const input = {
        contentId,
        contentUsageId,
        emojiCode,
        emojiAlias,
      }
      await form.submit(input, {
        updater: (store) => {
          updateRelayContentReaction(store, {
            contentId,
            emojiCode,
            contentUsageId,
            emojiAlias,
          })
        },
        optimisticUpdater: (store) => {
          updateRelayContentReaction(store, {
            contentId,
            emojiCode,
            contentUsageId,
            emojiAlias,
          })
        },
      })
    }
  }
}

export function ContentReactionButtonSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <IconButton
        classes={{
          root: classes.reactionIcon,
        }}
      >
        <ReactionFaceIcon width={16} height={16} />
      </IconButton>
      <DiscoTextSkeleton width={"50px"} />
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  reactionIcon: {
    "& > path": {
      color: theme.palette.groovy.neutral[500],
    },

    width: "16px",
    height: "16px",
    "& > svg": {
      width: "16px",
      height: "16px",
      marginBottom: "2px",
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  reactionButton: {
    width: "fit-content",
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[700],
    gap: theme.spacing(0.25),
    padding: theme.spacing(0.75, 1),
    height: "32px",

    "& p": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[700],
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      whiteSpace: "nowrap",
    },
    "&:hover p": {
      color: theme.palette.text.primary,
    },

    "& span": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    "&:hover svg path": {
      stroke: theme.palette.text.primary,
    },
  },
}))

export default Relay.withSkeleton({
  component: ContentReactionButton,
  skeleton: ContentReactionButtonSkeleton,
})
