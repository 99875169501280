import { DiscoButton, DiscoDropdown, SelectOption } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"

interface MembershipTypeFilterProps extends TestIDProps {
  filterState: MemberGroupTableToolbarState
  setFilterState: (state: MemberGroupTableToolbarState) => void
}

export type MemberGroupTableToolbarState = {
  search?: string | null
  selectedType: MemberGroupMembershipTypeOption | null
}

export type MemberGroupMembershipTypeOption = SelectOption<"admin" | "member" | null>

export const MEMEBERSHIP_TYPE_OPTIONS: MemberGroupMembershipTypeOption[] = [
  { value: null, title: "All Roles" },
  {
    value: "admin",
    title: "Admin",
  },
  {
    value: "member",
    title: "Member",
  },
]

const MembershipTypeFilter = (props: MembershipTypeFilterProps) => {
  const { filterState, setFilterState } = props

  return (
    <DiscoDropdown
      key={`${filterState.selectedType}`}
      testid={"MembershipTypeFilter"}
      menuButton={(btnProps) => (
        <DiscoButton {...btnProps}>{filterState.selectedType?.title}</DiscoButton>
      )}
    >
      {MEMEBERSHIP_TYPE_OPTIONS.map((option) => (
        <DiscoDropdownItem
          key={`${option.title}-${option.value}`}
          title={option.title}
          onClick={() => handleFilterChange(option)}
          testid={`MembershipTypeFilter.option.${option.value}`}
        />
      ))}
    </DiscoDropdown>
  )

  function handleFilterChange(selectedType: MemberGroupMembershipTypeOption | null) {
    setFilterState({ ...filterState, selectedType })
  }
}

export default MembershipTypeFilter
