/**
 * @generated SignedSource<<f39ee765de5c91f384768804eb8bbe93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type UpdateProductVisibilityInput = {
  id: string;
  registrationAvailability?: ProductRegistrationAvailability | null;
  status?: ProductStatus | null;
};
export type PublishExperienceButtonMutation$variables = {
  input: UpdateProductVisibilityInput;
};
export type PublishExperienceButtonMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly status: ProductStatus;
      readonly " $fragmentSpreads": FragmentRefs<"ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment">;
    } | null;
  };
};
export type PublishExperienceButtonMutation = {
  response: PublishExperienceButtonMutation$data;
  variables: PublishExperienceButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishExperienceButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductVisibilityResponse",
        "kind": "LinkedField",
        "name": "updateProductVisibility",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublishExperienceButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateProductVisibilityResponse",
        "kind": "LinkedField",
        "name": "updateProductVisibility",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "parentPathwayProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasDraftChildProducts",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30ad15c638036d2b5b744ed526e0c07b",
    "id": null,
    "metadata": {},
    "name": "PublishExperienceButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PublishExperienceButtonMutation(\n  $input: UpdateProductVisibilityInput!\n) {\n  response: updateProductVisibility(input: $input) {\n    node {\n      id\n      status\n      ...ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment ProductVisibilityBanner_parentPathwaysHaveDraftChildProductsFragment on Product {\n  parentPathwayProducts {\n    edges {\n      node {\n        id\n        hasDraftChildProducts\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "031703d870baf61047ae8cce326eb7db";

export default node;
