/**
 * @generated SignedSource<<2ffb486b2f1fbfe6826aedef4b2d6f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductRegistrationType = "application" | "closed" | "open" | "waitlist" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type CloneCourseInput = {
  badge?: BadgeInput | null;
  curriculumContentModuleUsages?: ReadonlyArray<CloneCurriculumContentModuleUsageInput> | null;
  events?: ReadonlyArray<CloneCourseEventOption> | null;
  includeChatChannels?: boolean | null;
  includeCollections?: boolean | null;
  includeInstructors?: boolean | null;
  includeLandingPage?: boolean | null;
  includeManagers?: boolean | null;
  includeTeachers?: boolean | null;
  name: string;
  productId: string;
  startDate?: string | null;
};
export type BadgeInput = {
  assetId?: string | null;
  color?: string | null;
  emoji?: string | null;
  icon?: string | null;
  kind: BadgeKind;
  mediaUrl?: string | null;
};
export type CloneCourseEventOption = {
  occurrences: ReadonlyArray<CloneOccurrenceOption>;
  sourceId: string;
};
export type CloneOccurrenceOption = {
  duration: number;
  sourceId: string;
  startDatetime: string;
  timezone: string;
};
export type CloneCurriculumContentModuleUsageInput = {
  contentUsages?: ReadonlyArray<CloneContentUsageInput> | null;
  releasedAt?: string | null;
  sourceId: string;
};
export type CloneContentUsageInput = {
  dueAt: string;
  sourceId: string;
};
export type CloneProductModalContextMutation$variables = {
  input: CloneCourseInput;
};
export type CloneProductModalContextMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly destinationProduct: {
        readonly badge: {
          readonly color: string | null;
          readonly emoji: string | null;
          readonly icon: string | null;
          readonly kind: BadgeKind;
          readonly mediaUrl: string | null;
        } | null;
        readonly capacity: number | null;
        readonly description: string | null;
        readonly endDate: string | null;
        readonly id: string;
        readonly name: string;
        readonly registrationAvailability: ProductRegistrationAvailability;
        readonly registrationType: ProductRegistrationType;
        readonly slug: string;
        readonly startDate: string | null;
        readonly status: ProductStatus;
        readonly type: ProductType;
      } | null;
      readonly id: string;
    } | null;
  };
};
export type CloneProductModalContextMutation = {
  response: CloneProductModalContextMutation$data;
  variables: CloneProductModalContextMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationAvailability",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CloneProductModalContextMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CloneCourseResponse",
        "kind": "LinkedField",
        "name": "cloneCourse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CloneJob",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "destinationProduct",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "badge",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CloneProductModalContextMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CloneCourseResponse",
        "kind": "LinkedField",
        "name": "cloneCourse",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CloneJob",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "destinationProduct",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "badge",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0751506b3263e2cf189f547a416e2f04",
    "id": null,
    "metadata": {},
    "name": "CloneProductModalContextMutation",
    "operationKind": "mutation",
    "text": "mutation CloneProductModalContextMutation(\n  $input: CloneCourseInput!\n) {\n  response: cloneCourse(input: $input) {\n    node {\n      id\n      destinationProduct {\n        id\n        name\n        type\n        slug\n        status\n        description\n        registrationAvailability\n        registrationType\n        capacity\n        startDate\n        endDate\n        badge {\n          kind\n          icon\n          color\n          emoji\n          mediaUrl\n          id\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbd566a537719f8a58b28a815f00a549";

export default node;
