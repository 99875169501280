/**
 * @generated SignedSource<<ff4542df2888c296c181d5e3344896e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRegistrationAvailability = "hidden" | "private" | "public" | "%future added value";
export type ProductSelectQuery$variables = {
  hideNonEventAppProducts: boolean;
  hideNonPublic: boolean;
  id: string;
  ids?: ReadonlyArray<string> | null;
  type?: string | null;
  viewerIsMember?: boolean | null;
};
export type ProductSelectQuery$data = {
  readonly organization: {
    readonly products?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly badge: {
            readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
          } | null;
          readonly hasEventsApp?: boolean;
          readonly id: string;
          readonly isAutoJoined: boolean;
          readonly name: string;
          readonly registrationAvailability: ProductRegistrationAvailability;
          readonly slug: string;
          readonly viewerPermissions: ReadonlyArray<string>;
          readonly " $fragmentSpreads": FragmentRefs<"ProductDropdownItemFragment">;
        };
      }>;
    };
  } | null;
};
export type ProductSelectQuery = {
  response: ProductSelectQuery$data;
  variables: ProductSelectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hideNonEventAppProducts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hideNonPublic"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewerIsMember"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "hideNonPublic",
    "variableName": "hideNonPublic"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  },
  {
    "kind": "Variable",
    "name": "viewerIsMember",
    "variableName": "viewerIsMember"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAutoJoined",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerPermissions",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registrationAvailability",
  "storageKey": null
},
v14 = {
  "condition": "hideNonEventAppProducts",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": "hasEventsApp",
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "events"
        }
      ],
      "kind": "ScalarField",
      "name": "hasProductApp",
      "storageKey": "hasProductApp(kind:\"events\")"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductSelectQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badge",
                            "plural": false,
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "BadgeFragment"
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ProductDropdownItemFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductSelectQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v6/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ProductNodeConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badge",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "icon",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaUrl",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endsAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d731961291a8d488d4d4fb682c4c5177",
    "id": null,
    "metadata": {},
    "name": "ProductSelectQuery",
    "operationKind": "query",
    "text": "query ProductSelectQuery(\n  $id: ID!\n  $type: String\n  $hideNonPublic: Boolean!\n  $ids: [ID!]\n  $viewerIsMember: Boolean\n  $hideNonEventAppProducts: Boolean!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      products(type: $type, hideNonPublic: $hideNonPublic, ids: $ids, viewerIsMember: $viewerIsMember) {\n        edges {\n          node {\n            id\n            name\n            slug\n            isAutoJoined\n            viewerPermissions\n            registrationAvailability\n            badge {\n              ...BadgeFragment\n              id\n            }\n            hasEventsApp: hasProductApp(kind: events) @include(if: $hideNonEventAppProducts)\n            ...ProductDropdownItemFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment ProductDropdownItemFragment on Product {\n  name\n  type\n  startsAt\n  endsAt\n  status\n  badge {\n    ...BadgeFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ccc2ddc50f80137f92740324dcb4515";

export default node;
