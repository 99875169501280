import { useProductRoleLabels } from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ProductRole } from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { DiscoSelect, DiscoTooltip, DiscoTooltipProps, SelectOption } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  value: ProductRole
  onChange: (role: ProductRole | null) => void
  disabledValues?: { role: ProductRole; tooltipContent?: DiscoTooltipProps["content"] }[]
  fullWidth?: boolean
}

function ProductRoleSelect({
  testid = "ProductRoleSelect",
  value,
  onChange,
  disabledValues,
  fullWidth,
}: Props) {
  const productRoleLabels = useProductRoleLabels()
  const classes = useStyles({ fullWidth: Boolean(fullWidth) })

  return (
    <DiscoSelect
      testid={`${testid}`}
      autoComplete={false}
      disableClearable
      options={getOptions()}
      value={value}
      renderOption={(option) => {
        return (
          <DiscoTooltip
            content={option.disabledTooltipContent}
            disabled={!option.disabled}
          >
            <span data-testid={`${testid}-answer-${option.value}`}>{option.title}</span>
          </DiscoTooltip>
        )
      }}
      onChange={(v) => onChange(v)}
      classes={{ root: classes.root }}
      className={classes.container}
      autoWidth
    />
  )

  function getOptions() {
    const roles: SelectOption<ProductRole>[] = []

    for (const role of ["member", "instructor", "manager"] as ProductRole[]) {
      const disabledProps = disabledValues?.find((v) => v.role === role)

      roles.push({
        value: role as ProductRole,
        title: productRoleLabels[role].singular,
        disabled: Boolean(disabledProps),
        disabledTooltipContent: disabledProps?.tooltipContent,
      })
    }
    return roles
  }
}

type StyleProps = {
  fullWidth: boolean
}

const useStyles = makeUseStyles(() => ({
  container: ({ fullWidth }: StyleProps) => ({
    width: fullWidth ? "100%" : undefined,
  }),
  root: ({ fullWidth }: StyleProps) => ({
    flexShrink: fullWidth ? undefined : 0,
    width: fullWidth ? "100%" : "auto",
  }),
}))

export default ProductRoleSelect
