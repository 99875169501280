/**
 * @generated SignedSource<<5172941ee7d1cab145561495f2529b6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMemberGroupModalFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "CreateMemberGroupModalFragment";
};
export type CreateMemberGroupModalFragment$key = {
  readonly " $data"?: CreateMemberGroupModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateMemberGroupModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "dca8d9fd1d7ea88e6f99d7a22ad40afb";

export default node;
