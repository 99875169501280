/**
 * @generated SignedSource<<dca4cc00e2d7fb06eab8faf6d2ea7a79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneCurriculumModalStepDueDatesFragment$data = {
  readonly id: string;
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly content: {
          readonly children: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly content: {
                  readonly name: string | null;
                };
                readonly dueAt: string | null;
                readonly entityId: string;
                readonly id: string;
              };
            }>;
          } | null;
          readonly name: string | null;
        };
        readonly id: string;
        readonly releasedAt: string | null;
      };
    }>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "CloneCurriculumModalStepDueDatesFragment";
};
export type CloneCurriculumModalStepDueDatesFragment$key = {
  readonly " $data"?: CloneCurriculumModalStepDueDatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneCurriculumModalStepDueDatesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneCurriculumModalStepDueDatesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "releasedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContentUsageNodeConnection",
                      "kind": "LinkedField",
                      "name": "children",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentUsageNodeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ContentUsage",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "dueAt",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "entityId",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Content",
                                  "kind": "LinkedField",
                                  "name": "content",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Curriculum",
  "abstractKey": null
};
})();

(node as any).hash = "187d29507ce4b4db9c93ef4862ccde29";

export default node;
