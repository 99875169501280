import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductPricingDetailsFragment$key } from "@/pricing/__generated__/ProductPricingDetailsFragment.graphql"
import {
  generateReadablePricing,
  getPricingKindLabel,
  usePricingDisplayValue,
} from "@/pricing/pricingUtils"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText, DiscoTextSkeleton, DiscoTooltip } from "@disco-ui"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  productKey: ProductPricingDetailsFragment$key
  ignoreViewersPlan?: boolean
  classes?: Partial<ClassNameMap<"price" | "details" | "container">>
}

function ProductPricingDetails({
  testid,
  productKey,
  ignoreViewersPlan = false,
  classes: customClasses,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const { currency } = activeOrganization!
  const experienceLabel = useLabel("admin_experience")
  const classes = useStyles()

  const product = useFragment<ProductPricingDetailsFragment$key>(
    graphql`
      fragment ProductPricingDetailsFragment on Product
      @argumentDefinitions(
        hideNonPublic: { type: "Boolean!" }
        hideDrafts: { type: "Boolean!" }
      ) {
        id
        type
        registrationPricing {
          basePrice
          pricing {
            frequency
            kind
            ...pricingUtils_usePricingDisplayValue
          }
        }
        registrationPricingRange {
          minPricing {
            ...pricingUtils_usePricingDisplayValue
          }
          maxPricing {
            ...pricingUtils_usePricingDisplayValue
          }
        }
        ...usePermissionsFragment
        includedInBenefits(hideNonPublic: $hideNonPublic, hideDrafts: $hideDrafts) {
          edges {
            node {
              id
              membershipPlan {
                id
                name
              }
              pricing {
                frequency
                kind
                amountCents
                ...pricingUtils_usePricingDisplayValue
              }
            }
          }
        }
      }
    `,
    productKey
  )

  const pricingLabel = product.registrationPricing?.pricing
    ? getPricingKindLabel(
        product.registrationPricing.pricing.kind,
        product.registrationPricing.pricing.frequency
      )
    : ""

  const pricingValue = usePricingDisplayValue({
    pricingKey: product.registrationPricing?.pricing,
    currency: activeOrganization?.currency,
    basePrice: product.registrationPricing?.basePrice,
  })

  const minPricingValue = usePricingDisplayValue({
    pricingKey: product.registrationPricingRange?.minPricing,
    currency: activeOrganization?.currency,
    length: "short",
  })

  const maxPricingValue = usePricingDisplayValue({
    pricingKey: product.registrationPricingRange?.maxPricing,
    currency: activeOrganization?.currency,
    length: "short",
  })

  const benefits = Relay.connectionToArray(product.includedInBenefits)
  const kind = getKind()

  if (!product) return null

  return (
    <div className={customClasses?.container}>
      {kind === "single" ? (
        <div>
          <DiscoText
            testid={`${testid}.price`}
            className={classNames(classes.price, customClasses?.price)}
          >
            {pricingValue}
          </DiscoText>

          {Boolean(product.registrationPricing?.basePrice) && (
            <DiscoText className={classNames(classes.details, customClasses?.details)}>
              {pricingLabel}
            </DiscoText>
          )}
        </div>
      ) : (
        <div>
          <DiscoText
            testid={`${testid}.price`}
            className={classNames(classes.price, customClasses?.price)}
          >{`${minPricingValue} - ${maxPricingValue}`}</DiscoText>

          <div className={classes.priceDetails}>
            <DiscoText
              component={"span"}
              className={classNames(classes.details, customClasses?.details)}
            >
              {"Multiple Prices"}
            </DiscoText>

            <DiscoTooltip
              content={
                <div className={classes.planPricings}>
                  <DiscoText
                    variant={"body-xs-600"}
                    color={"text.secondary"}
                    className={classes.priceDetailsText}
                    marginBottom={1}
                  >
                    {`This ${experienceLabel.singular} is available on multiple plans, each with a different price.`}
                  </DiscoText>

                  {benefits.map((benefit) => {
                    return (
                      <DiscoText
                        key={benefit.membershipPlan.id}
                        variant={"body-xs-600"}
                        color={"text.secondary"}
                        className={classNames(
                          classes.priceDetailsText,
                          classes.planPricingsText
                        )}
                      >
                        {"•"}
                        <DiscoText
                          variant={"body-xs-700"}
                          color={"text.secondary"}
                          className={classes.priceDetailsText}
                        >
                          {`${generateReadablePricing(
                            benefit.pricing,
                            currency,
                            "short"
                          )}:`}
                        </DiscoText>
                        {benefit.membershipPlan.name}
                      </DiscoText>
                    )
                  })}
                </div>
              }
            />
          </div>
        </div>
      )}
    </div>
  )

  function getKind() {
    // If there is a range, show it if the user is not on any plan, or if we ignore the viewers plan
    if (product.registrationPricingRange) {
      if (ignoreViewersPlan) return "range"
      if (!activeOrganization?.viewerActiveMembershipPlan) return "range"
    }

    // Otherwise if there is no range, or if the user is on a plan, show a single price
    return "single"
  }
}

const useStyles = makeUseStyles((theme) => ({
  priceDetails: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  priceDetailsText: {
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.neutral[100]
        : theme.palette.common.white,
  },
  planPricings: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  planPricingsText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  price: {
    color: theme.palette.text.primary,
    ...theme.typography["body-md"],
    fontWeight: 600,
  },
  details: {
    color: theme.palette.text.secondary,
    ...theme.typography["body-md"],
  },
}))

export function ProductPricingDetailsSkeleton({
  classes: customClasses,
}: Pick<Props, "classes">) {
  const classes = useStyles()
  return (
    <div>
      <div>
        <DiscoTextSkeleton className={classNames(classes.price, customClasses?.price)} />
        <DiscoTextSkeleton
          className={classNames(classes.details, customClasses?.details)}
        />
      </div>
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ProductPricingDetails),
  skeleton: ProductPricingDetailsSkeleton,
})
