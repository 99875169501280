/**
 * @generated SignedSource<<d0f791ac21a3f14fd68110aa33fe92ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CloneJobStatus = "error" | "in_progress" | "success" | "%future added value";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductCardFragment$data = {
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly cover: string;
  readonly id: string;
  readonly slug: string;
  readonly sourceCloneJob: {
    readonly status: CloneJobStatus;
  } | null;
  readonly viewerMembership: {
    readonly role: ProductRole;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceAdminDropdownFragment" | "ProductCardCallToActionButtonFragment" | "ProductCardDetailsFragment">;
  readonly " $fragmentType": "ProductCardFragment";
};
export type ProductCardFragment$key = {
  readonly " $data"?: ProductCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CloneJob",
      "kind": "LinkedField",
      "name": "sourceCloneJob",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardCallToActionButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardDetailsFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "3136f57c075567bbbe71e4db9321c233";

export default node;
