/**
 * @generated SignedSource<<d59a32fb0587d34ec573f33104cd0985>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CloneProductModalContext_CanCloneEventsFragment$data = {
  readonly allOccurrences: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly meetingProvider: {
          readonly zoomConnection: {
            readonly details: {
              readonly hasWebinarAddon: boolean;
            };
          };
        } | null;
        readonly videoRoomType: string;
      };
    }>;
  };
  readonly " $fragmentType": "CloneProductModalContext_CanCloneEventsFragment";
};
export type CloneProductModalContext_CanCloneEventsFragment$key = {
  readonly " $data"?: CloneProductModalContext_CanCloneEventsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CloneProductModalContext_CanCloneEventsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CloneProductModalContext_CanCloneEventsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "allOccurrences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OccurrenceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Occurrence",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "videoRoomType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MeetingProvider",
                  "kind": "LinkedField",
                  "name": "meetingProvider",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ZoomConnection",
                      "kind": "LinkedField",
                      "name": "zoomConnection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ZoomOAuthUserResource",
                          "kind": "LinkedField",
                          "name": "details",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hasWebinarAddon",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "4db292ed63a2d9e14689f423a64205ec";

export default node;
