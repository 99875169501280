import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { CreateSubGroupFormMutation } from "@/product/common/member-group/common/create/form/__generated__/CreateSubGroupFormMutation.graphql"
import { CreateSubGroupFormQuery } from "@/product/common/member-group/common/create/form/__generated__/CreateSubGroupFormQuery.graphql"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import MemberGroupFormFields, {
  MemberGroupFormFieldsSkeleton,
} from "@/product/common/member-group/common/MemberGroupFormFields"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay"

interface CreateSubGroupFormProps {
  parentMemberGroupId: GlobalID
  selectedMembershipIds?: GlobalID[]
  onCancel: () => void
  onSave: () => void
}

function CreateSubGroupForm({
  parentMemberGroupId,
  selectedMembershipIds = [],
  onCancel,
  onSave,
}: CreateSubGroupFormProps) {
  const { node } = useLazyLoadQuery<CreateSubGroupFormQuery>(
    graphql`
      query CreateSubGroupFormQuery($memberGroupId: ID!, $membershipIds: [ID!]) {
        node(id: $memberGroupId) {
          ... on MemberGroup {
            id
            __typename
            organizationId
            product {
              id
              name
            }
            visibility
            memberGroupMemberships(membershipIds: $membershipIds) {
              edges {
                node {
                  id
                  userId
                }
              }
            }
            ...MemberGroupTagFragment
          }
        }
      }
    `,
    {
      memberGroupId: parentMemberGroupId,
      membershipIds: selectedMembershipIds,
    },
    { fetchPolicy: "network-only" }
  )
  const parentMemberGroup = Relay.narrowNodeType(node, "MemberGroup")
  const memberGroupMemberships = Relay.connectionToArray(
    parentMemberGroup?.memberGroupMemberships
  )

  const form = useFormStore<CreateSubGroupFormMutation, CreateMemberGroupFormState>(
    graphql`
      mutation CreateSubGroupFormMutation(
        $input: CreateMemberGroupInput!
        $connections: [ID!]!
      ) {
        response: createMemberGroup(input: $input) {
          node
            @prependNode(connections: $connections, edgeTypeName: "MemberGroupNodeEdge") {
            id
            parentMemberGroup {
              ...AdminGroupsListRowFragment
              ...MemberGroupSubGroupListFragment
            }
            product {
              ...ExperienceSettingsGroupsTabFragment
            }
            memberGroupMemberships {
              edges {
                node {
                  id
                  memberGroup {
                    ...MemberGroupTagFragment
                  }
                  productMembership {
                    ...useMemberGroupTagsList_ProductMembershipFragment
                  }
                  organizationMembership {
                    ...useMemberGroupTagsList_OrganizationMembershipFragment
                  }
                }
              }
            }
            ...GroupsListChildGroupRowFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: parentMemberGroup?.organizationId || "",
      sourceMemberGroupId: parentMemberGroupId,
      name: "",
      color: "#FFF5D6",
      selectedUserIds: memberGroupMemberships.map((mgm) => mgm.userId),
      visibility: parentMemberGroup?.visibility,
      description: "",
      kind: "custom",
      role: null,
      product: parentMemberGroup?.product || null,
      // Must update assetId and url if the avatar has changed
      assetId: null,
      avatarURL: null,
    }
  )

  const membersLabel = useLabel(
    parentMemberGroup?.product?.id ? "product_member" : "organization_member"
  )
  if (!parentMemberGroup) return null

  const tag = (
    <DiscoText>
      {`Add ${membersLabel.plural} from: `}
      <MemberGroupTag memberGroupKey={parentMemberGroup} showFullDetails />
    </DiscoText>
  )

  return (
    <Form
      testid={"CreateSubGroupForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onCancel}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            form={form}
            testid={"CreateMemberGroupForm.submit"}
            disabled={!form.isChanged || form.isSubmitting}
          >
            {"Create Sub-Group"}
          </Form.SubmitButton>
        </>
      }
    >
      <MemberGroupFormFields form={form} tableLabel={tag} isSubGroup />
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(
      {
        organizationId: parentMemberGroup!.organizationId,
        productId: form.state.product?.id,
        userIds: form.state.selectedUserIds,
        color: form.state.color,
        name: form.state.name,
        parentMemberGroupId,
        kind: "custom",
        assetId: form.state.assetId,
        description: form.state.description,
      },
      {
        connections: [
          ConnectionHandler.getConnectionID(
            parentMemberGroupId,
            "AdminGroupsListRow__childrenGroups"
          ),
          ConnectionHandler.getConnectionID(
            parentMemberGroupId,
            "MemberGroupSubGroupList__childrenGroups"
          ),
        ],
      }
    )

    if (!didSave) return

    displaySuccessToast({
      message: "Sub-group created!",
      testid: "CreateSubGroupForm.success-toast",
    })
    onSave()
  }
}

export default Relay.withSkeleton<CreateSubGroupFormProps>({
  component: observer(CreateSubGroupForm),
  skeleton: MemberGroupFormFieldsSkeleton,
})
