/**
 * @generated SignedSource<<d351f74fe0b2dfb7c84183c32798f0a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductBadgeStackFragment_membership$data = {
  readonly memberships: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly product: {
          readonly badge: {
            readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
          } | null;
          readonly id: string;
        } | null;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductBadgeStackFragment">;
  readonly " $fragmentType": "ProductBadgeStackFragment_membership";
};
export type ProductBadgeStackFragment_membership$key = {
  readonly " $data"?: ProductBadgeStackFragment_membership$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductBadgeStackFragment_membership">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideDrafts"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hideNonPublic"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "memberships"
        ]
      }
    ]
  },
  "name": "ProductBadgeStackFragment_membership",
  "selections": [
    {
      "alias": "memberships",
      "args": [
        {
          "kind": "Variable",
          "name": "hideDrafts",
          "variableName": "hideDrafts"
        },
        {
          "kind": "Variable",
          "name": "hideNonPublic",
          "variableName": "hideNonPublic"
        }
      ],
      "concreteType": "MembershipBenefitNodeConnection",
      "kind": "LinkedField",
      "name": "__ProductCountChip_memberships_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MembershipBenefitNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MembershipBenefit",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Badge",
                      "kind": "LinkedField",
                      "name": "badge",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "BadgeFragment"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfoObjectType",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductBadgeStackFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "a405974ba1760fbc2fac5108bbe0f769";

export default node;
