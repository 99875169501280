/**
 * @generated SignedSource<<cf7ee4cc269509d1568aa1b932c99caa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CheckoutFormApplicationSection_Checkout$data = {
  readonly application: {
    readonly questions: ReadonlyArray<{
      readonly id: string;
      readonly richEditorBody: string;
    }>;
  } | null;
  readonly " $fragmentType": "CheckoutFormApplicationSection_Checkout";
};
export type CheckoutFormApplicationSection_Checkout$key = {
  readonly " $data"?: CheckoutFormApplicationSection_Checkout$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutFormApplicationSection_Checkout">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutFormApplicationSection_Checkout",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuestionAnswers",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CheckoutQuestion",
          "kind": "LinkedField",
          "name": "questions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "richEditorBody",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Checkout",
  "abstractKey": null
};

(node as any).hash = "3b23b54bf147d57b3477eb84085dc325";

export default node;
