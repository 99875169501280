/**
 * @generated SignedSource<<a315095439e29474cb48e0fda9ba0080>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type InvitationKind = "admin" | "member" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CheckoutStore_Checkout$data = {
  readonly application: {
    readonly answers: ReadonlyArray<string>;
  } | null;
  readonly hasApplication: boolean;
  readonly id: string;
  readonly invitation: {
    readonly kind: InvitationKind;
    readonly message: string;
    readonly organizationId: string;
    readonly productId: string | null;
  } | null;
  readonly isOrganizationPublic: boolean | null;
  readonly occurrence: {
    readonly allowGuestAccess: boolean;
    readonly id: string;
  } | null;
  readonly organizationForm: {
    readonly answers: ReadonlyArray<string>;
  } | null;
  readonly profile: {
    readonly email: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly timezone: string | null;
    readonly userId: string | null;
  };
  readonly quote: {
    readonly basePrice: number;
    readonly currency: string;
    readonly discount: {
      readonly code: string | null;
      readonly id: string;
    } | null;
    readonly finalPrice: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutFormApplicationSection_Checkout" | "CheckoutFormDiscountInput_Checkout" | "CheckoutFormOrganizationFormSection_Checkout" | "ProductRegistrationPaymentStep_Checkout">;
  readonly " $fragmentType": "CheckoutStore_Checkout";
};
export type CheckoutStore_Checkout$key = {
  readonly " $data"?: CheckoutStore_Checkout$data;
  readonly " $fragmentSpreads": FragmentRefs<"CheckoutStore_Checkout">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "answers",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckoutStore_Checkout",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductRegistrationPaymentStep_Checkout"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckoutFormDiscountInput_Checkout"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckoutFormApplicationSection_Checkout"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CheckoutFormOrganizationFormSection_Checkout"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasApplication",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOrganizationPublic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuote",
      "kind": "LinkedField",
      "name": "quote",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "basePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Discount",
          "kind": "LinkedField",
          "name": "discount",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Occurrence",
      "kind": "LinkedField",
      "name": "occurrence",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowGuestAccess",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuestionAnswers",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckoutQuestionAnswers",
      "kind": "LinkedField",
      "name": "organizationForm",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invitation",
      "kind": "LinkedField",
      "name": "invitation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Checkout",
  "abstractKey": null
};
})();

(node as any).hash = "d05de70df5e38d723a388ecfeaa74edf";

export default node;
