/**
 * @generated SignedSource<<090aa51d90347bcb8ca9e3bfb682032b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDropdownItemFragment$data = {
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly endsAt: string | null;
  readonly name: string;
  readonly startsAt: string | null;
  readonly status: ProductStatus;
  readonly type: ProductType;
  readonly " $fragmentType": "ProductDropdownItemFragment";
};
export type ProductDropdownItemFragment$key = {
  readonly " $data"?: ProductDropdownItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDropdownItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDropdownItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "199a8fbf01fcba626d1a83572338fc67";

export default node;
