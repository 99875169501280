import { useIsPreviewMode } from "@/core/context/PreviewModeContext"
import { ProductCardCallToActionButtonFragment$key } from "@/product/card/__generated__/ProductCardCallToActionButtonFragment.graphql"
import useIsAdminViewingAsMember from "@/product/util/hook/useIsAdminViewingAsMember"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton, DiscoChip, DiscoIcon } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props extends TestIDProps {
  productKey: ProductCardCallToActionButtonFragment$key
  borderRadius?: number
  isDashboardBlock?: boolean
}

function ProductCardCallToActionButton({
  testid: testidProp = "ProductCardCallToActionButton",
  productKey,
  borderRadius,
  isDashboardBlock = false,
}: Props) {
  const isPreviewMode = useIsPreviewMode()
  const isAdminViewingAsLearner = useIsAdminViewingAsMember()
  const classes = useStyles()
  const theme = useTheme()

  const product = useFragment<ProductCardCallToActionButtonFragment$key>(
    graphql`
      fragment ProductCardCallToActionButtonFragment on Product {
        slug
        hasEnded
        viewerMembership {
          id
        }
        viewerApplication {
          id
        }
      }
    `,
    productKey
  )
  const testid = `${testidProp}.${product.slug}`

  // users that are members of the product
  if (product.viewerMembership && !isAdminViewingAsLearner && !isPreviewMode) {
    return (
      <>
        {!isDashboardBlock && (
          <DiscoChip
            testid={`${testid}.registered`}
            icon={<DiscoIcon icon={"done"} />}
            label={"Registered"}
            textVariant={"body-sm-600"}
            customTextColor={theme.palette.groovy.green[500]}
            color={"green"}
            className={classes.registered}
          />
        )}

        <DiscoButton
          borderRadius={borderRadius}
          testid={`${testid}.continue`}
          className={classes.clickable}
          width={isDashboardBlock ? "100%" : undefined}
        >
          {"Continue"}
        </DiscoButton>
      </>
    )
  }

  // users that are applicants for the product
  if (product.viewerApplication && !isAdminViewingAsLearner && !isPreviewMode) {
    return (
      <DiscoButton
        leftIcon={<DiscoIcon icon={"time"} />}
        color={"warning"}
        borderRadius={borderRadius}
        testid={`${testid}.under-review`}
        className={classes.clickable}
      >
        {"Under Review"}
      </DiscoButton>
    )
  }

  if (product.hasEnded) {
    return (
      <DiscoButton
        color={"grey"}
        leftIcon={<DiscoIcon icon={"ticket"} />}
        borderRadius={borderRadius}
        testid={`${testid}.closed`}
        disabled
      >
        {"Closed"}
      </DiscoButton>
    )
  }
  // users that aren't registered and haven't applied to the product
  return (
    <DiscoButton
      borderRadius={borderRadius}
      testid={`${testid}.view-more`}
      className={classes.clickable}
      variant={"outlined"}
      color={"grey"}
      width={"100%"}
    >
      {"View Details"}
    </DiscoButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  // Need to manually set cursor to pointer since there's no onClick added to the button
  // and the click is handled in the parent component
  clickable: {
    cursor: "pointer !important",
  },
  registered: {
    padding: theme.spacing(1, 0.5),
  },
}))

export default ProductCardCallToActionButton
